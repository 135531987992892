import { Component, OnInit} from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-myaccount-buttons',
    templateUrl: './my-account-buttons.component.html',
    styles: [`
        button {
            margin-left: 4px;
        }
        .active-orange {
            background-color: #F39C12 !important;
            color: white !important;
        }
        .btn-hover-orange:hover{
            background-color: #F39C12 !important;
            color: white !important;
        }

        @media (max-width: 767.98px) {
            .nav-links {
                display: none;
            }
            .mobile-menu {
                display: none;
            }
            .mobile-menu.show-mobile-menu {
                display: block;
                width: 100%;
            }
        }
        
        @media (min-width: 768px) {
            .nav-links {
                display: block;
            }
            .mobile-menu {
                display: none;
            }
        }
    `],
})
export class MyAccountButtons implements OnInit {
    isAdmin = false;
    showMobileMenu = false;
    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.authService.currentUser.subscribe((user) => {
            this.isAdmin = user.CustomOptions?.IsAdmin;
        });

    }
    toggleMenu(): void {
        this.showMobileMenu = !this.showMobileMenu;
    }

    
}
