<div style="display: flex; flex-direction: column; align-items: center;">
    <button class="d-md-none sm-full-width btn btn-primary float-sm-right my-1 " type="button" (click)="toggleMenu()">
        Menu <i class="fa fa-bars"></i>
    </button>

    <div class="mobile-menu" [ngClass]="{'show-mobile-menu': showMobileMenu, 'miles-card': showMobileMenu}" >
        <button routerLink="/customer/order" routerLinkActive="active-orange" 
        class="sm-full-width btn btn-primary float-sm-right my-1 btn-hover-orange">
            My Orders
        </button>
        <button routerLink="/customer/course" routerLinkActive="active-orange" 
        class="sm-full-width btn btn-primary float-sm-right my-1 btn-hover-orange">
            My Courses
        </button>
        <button routerLink="/customer/info" routerLinkActive="active-orange" 
        class="sm-full-width btn btn-primary float-sm-right my-1 btn-hover-orange">
            My Information
        </button>
        <button routerLink="/publicpages/childcare_training" routerLinkActive="active-orange" 
        class="sm-full-width btn btn-primary float-sm-right my-1 btn-hover-orange"
        (click)="toggleMenu()">
            Purchase Training
        </button>
    </div>

    <div class="nav-links">
        <button routerLink="/publicpages/childcare_training" routerLinkActive="active-orange" 
        class="sm-full-width btn btn-primary float-sm-right my-1 btn-hover-orange"
        (click)="toggleMenu()">
            Purchase Training
        </button>
        <button routerLink="/customer/info" routerLinkActive="active-orange" 
        class="sm-full-width btn btn-primary float-sm-right my-1 btn-hover-orange">
            My Information
        </button>
        <button routerLink="/customer/course" routerLinkActive="active-orange" 
        class="sm-full-width btn btn-primary float-sm-right my-1 btn-hover-orange">
            My Courses
        </button>
        <button routerLink="/customer/order" routerLinkActive="active-orange" 
        class="sm-full-width btn btn-primary float-sm-right my-1 btn-hover-orange">
            My Orders
        </button>
    </div>
</div>
